.wait-msg{
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 200px;
}
.err-box{
  width: 80%;
  height: 160px;
  margin: 0 auto;
  padding-top: 120px;
  display: flex;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.err-box-img{
  width: 70px;
  height: 70px;
}
.err-msg{
  font-size: 18px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.8);
}
.detailed-page {  
  background-image: url(../images/img_background.png);
  background-size: 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: #F2F2F2;
  border: 1px solid transparent;
}