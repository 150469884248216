#root{
    height: 100vh;
    position: relative;
    overflow: auto;
}
.home {
    background-image: url(images/img_background.png);
    background-size: 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-color: #F2F2F2;
    border: 1px solid transparent;
}

.Agent {
    display: inline-block;
    background-image: url(images/robot.png);
    width: 32px;
    height: 32px;
    background: #FFBA04;
    border-radius: 4px;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 10px;
}

.User {
    display: inline-block;
    background-image: url(images/user.png);
    width: 32px;
    height: 32px;
    background: #6A75FF;
    border-radius: 4px;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 10px;
}

/* dpr比值为2的css代码 */
@media screen and (-webkit-device-pixel-ratio: 2) {
    .banner {
        background-image: url(images/img_background@2x.png);
    }

    .Agent {
        background-image: url(images/robot@2x.png);
    }

    .User {
        background-image: url(images/user@2x.png);
    }
}

/*dpr比值为3的css代码 */
@media screen and (-webkit-device-pixel-ratio: 3) {
    .banner {
        background-image: url(images/img_background@3x.png);
    }

    .Agent {
        background-image: url(images/robot@3x.png);
    }

    .User {
        background-image: url(images/user@3x.png);
    }
}