.task {
    background: #fff;
    border-radius: 10px;
    margin: 12px 15px 0;
    padding: 12px;
}

.task-header {
    padding: 0 16px;
    height: 48px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;
}

.task-header>span>div {
    display: inline-block;
}

.task-header .am-list-extra {
    display: none;
}
.task-header .anticon{
    line-height: 48px;
}
.tasktable-header> div{
    display: inline-block;
}