.topboard {
    background: #fff;
    border-radius: 10px;
    margin: 121px 15px 0;
    height: 204px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topboardtxt {
    text-align: left;
    width: 100%;
    margin-bottom: 0;
    font-size: 12px;
    margin-top: -2px;
}

.iconicon-test{
    top: 130px;
    right: 30px;
    position: absolute;
    color: #3f95e4;
}

.am-list-item{
    background: none !important;
}
.am-list-item .am-list-line .am-list-extra{
    color: #fff !important;
}

.leading-out{
    top: 16px;
    right: 20px;
    position: absolute;
    color: #fff;
    font-size: 14px;
}

.tobBox{
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    background: #fff;
}

.tit-header{
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
}
.bor-bot{
    border-bottom: 0.5px solid #EBEBEB;
}
.bor-bot .am-list-item .am-list-line .am-list-extra{
    text-align: left !important;
    color: #289DFF !important;
    flex-basis: 100% !important;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    padding: 0 !important;
}
.box-xl{
    height: 70px;
    line-height: 70px;
    padding: 0 20px;
    font-size: 18px;
    color: #289DFF;
}
.bor-bot .am-list-item{padding: 0;}
.box-hr{
    height: 70px;
    line-height: 70px;
    padding: 0 20px;
    font-size: 18px;
    color: #289DFF;
    display: flex;
    justify-content: space-between;
}
.calendar{
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dt-box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dt-txt{
    color: #3A3A3A;
    font-size: 20px;
}
.dis {
    font-size: 12px;
    color: rgba(58, 58, 58, .4);
}
.num-box{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.num-box > span {
    font-size: 12px;
    margin-bottom: 4px;
}

.btn{
    width: 90%;
    margin: 30px auto;
}

.iconxiala{
    margin-left: 4px;
    color:  rgba(0, 0, 0, .3) ;
    font-size: 12px !important;
}

.committee {
    position: absolute;
    top: 74px;
    background: none;
    color: #fff;
    left: 50%;
    transform: translate(-50%, 0);
}
.committee .am-list-item .am-list-line .am-list-extra {
    flex-basis: 100% !important;
}
.committee .am-list-body{
    background: none;
}
.committee .am-list-body::before, .committee .am-list-body::after{
    height: 0 !important;
}

.am-list-item .am-list-line .am-list-content {
    color: #fff !important;
}
.close-box{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 6px;
    top: 30px;
}
.top-title {
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 30px;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);
}

.top-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.top-row .board {
    flex: 1;
    text-align: center;
    margin: 0 11px;
    overflow: hidden;
    word-break: keep-all;
}

.board-title {
    height: 38px;
    font-size: 18px;
    font-family: Avenir-Heavy, Avenir;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.8);
    line-height: 38px;
}

.board-label {
    background: rgba(239, 244, 255, 1);
    border-radius: 4px;
    height: 21px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 21px;
}

.board-num {
    height: 30px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    line-height: 30px;
}

.green {
    color: #00DBA1;
}

.red {
    color: #FF3257
}

.am-picker-col-item {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    word-break: break-all !important;
}
