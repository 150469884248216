.page{
  height: 100%;
}

@font-face {font-family: "iconfont";
  src: url('//at.alicdn.com/t/font_1663321_gjqxy9dp038.eot?t=1583141447941'); /* IE9 */
  src: url('//at.alicdn.com/t/font_1663321_gjqxy9dp038.eot?t=1583141447941#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAASIAAsAAAAACXAAAAQ5AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDVAqFPIQ3ATYCJAMgCxIABCAFhG0HZxsQCMgekiRFQgQUACgABXMRD//tZ943lMnPboFcqxAt0PFVUZVIQiEYV4uqqr5Csls0Iv//e9kt5FfRg4XS5CVNhyfzFgC/BivWSxfg6d8fK90cX7B8m9/WJaqpogBXcIHyIpsP9E7kRP0esjlmFViN9jPOJzDYJobR+bIaEVgqcLNA3OI5CiwbJmWEFLq2MfuWfUYLoOrplfEdAB+y349VSApLkprhtheulDJQsGDZPtZkwn8uJhgQ7+eAfhAZ+4FC3GNdN5gU0f1MDO5iUyeBrkvsFXdqH/t/JnEjJdei6vQvHiHJiqoZEQ2q5iTxUmzEgmWECBacEImFkYjMwihEYWE0orIwBtFYGGsyAnxfuw4Y8B3EUT0ch9GgkrLd0SLe19wWF44fcssnursvm+XKsDGb65IJbphhc1s624PDCo8h8mhmx6yob7lu/e5wp607167hRFjKcpLR4pUbTlzzlmhFMOHMlXPeRnqJaMWVU5t8pHp90arN569G6l6IDa+kq6+f3eh5QUvP2mVkMl3mvmy1x3IbhzduS1e6LnFd9joHQkRLRmNGzLGjdTqxVivS6yUGg1T7WqR7IzZ0SPXtAybs0I7/aPfsnTD9eG7i+dlnJ5wp0wwb/jbindPb8Le5wOCnTqd3EeAe9i6cEaAI+PVSyD5qO2WnS3pwvP3T4e0/Cq0Lv3cMh/T/Awb3/o/0C9YTP7kWFMe2EfvbF5+reDPzJg5Qmn/0KRlSav1RZs4FtEJvKu5Nbh86Rk3oeO7dN3pDmZlIhSbug08gS0Sqr2UH6G/v2GPD7TyHYV4+up4R1XUndEdsg+yX6E7UVY9Q1u3y0sQXpf1h9w0dSd/7n+ZAOeKw6bt3pocFPxHELVXKgAemCJkC6QFCMqja+8tlRtgMl+YkGt1nQ3IafS2MA5jNd00KAcg/jaeBmRxqMgFQTxg/ByCfMz7P/UUguLn377PBDjlf+6SAX5M+l2femZq7XvQDEM8W/1JcVQy7yjwpmntxZJFwVluzRqA7ZKZJGMjK7yTJBk/31GyenU/oNleQjGyFrNuOLcT9UE0ch6Y7BYN9yg+e2EhgIsoYe00VENbZD8kaPyBb5y62EJ9AtcVbaNaFCQym4XLMiZ3BWxcLmCKYQS0jEU+rlGxoJdZ5YRPmhsopoS7NyBIsaPgASoxLaCfKsRILe+ygGcYlEcIiVlApUJm4GJbLVUgtqNowTeJaCVFnxcez3BvF0SoFWDtHgFEIjIG0GAnh0VSU2GQ6Yd35+SYYZyg5ijDS0eWVwAQa/OxIojgJM7DlKuWsjufyEI1hOEkIgoWwBCoKSJlwYHKKqiBqflUbjEbEaV0RVssSLyexc1Vxx9coXmnxP7MrXrtHihwlarQYRY+xdnUopWzhzUbwlJwyDSs0xLSVZ7DNY1k+mOXlBAumSjyCWLUyYwnWEAAAAAA=') format('woff2'),
  url('//at.alicdn.com/t/font_1663321_gjqxy9dp038.woff?t=1583141447941') format('woff'),
  url('//at.alicdn.com/t/font_1663321_gjqxy9dp038.ttf?t=1583141447941') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('//at.alicdn.com/t/font_1663321_gjqxy9dp038.svg?t=1583141447941#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconguanbi:before {
  content: "\e608";
}

.iconxiala:before {
  content: "\e611";
}

.iconlast:before {
  content: "\e679";
}

.iconhide:before {
  content: "\e67a";
}

.iconicon_filter:before {
  content: "\e67b";
}

.iconnext:before {
  content: "\e67c";
}

.iconicon-test:before {
  content: "\e67d";
}







.mb{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0,0,0,.65);
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 90%;
  background: #fff;
  margin: 0 auto;
  padding: 14px 18px;
  border-radius: 6px;
  color: #000000;
  line-height: 22px;
}
.box > strong {
  display: block;
  font-size: 12px;
}
.box > span {
  font-size: 12px;
}