.card{
  margin: 10px 15px;
  position: relative;
  border-radius: 10px;
}
.am-card-body, .am-card.am-card-full {
  border: none !important;
}
.am-card.am-card-full::before {
  background: none !important;
}
.tit{
  height: 30px;
  line-height: 30px;
  text-align: left;
  display: block;
  font-size: 20px;
  font-weight: 600;  
}
.icon{
  position: absolute;
  right: 25px;
  top: 14px;
}