.task-detail {
    height: 100vh;
    overflow: auto;
    background-color: #F2F2F2;
}

.task-customer {
    background: #fff;
    border-radius: 10px;
    margin: 12px 15px 0;
    padding: 12px;
}

.task-row {
    margin: 20px 20px 10px;
}

.task-list {
    list-style: none;
    padding-left: 10px;
    margin: 20px 0;
    font-size: 13px;
    line-height: 26px;
}

.task-talk {
    list-style: none;
    padding: 0;
}

.task-talk li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
}

.talk_c {
    flex: 1;
    background: #FFBA04;
    padding: 6px;
    border-radius: 5px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 19px;
}

.talk_Agent {
    background: #FFBA04;
}

.talk_User {
    background: #6A75FF;
}

.remark-num {
    text-align: right;
    color: rgba(0, 0, 0, 0.3);
}