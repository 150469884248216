.intention {
    background: #fff;
    border-radius: 10px;
    margin: 12px 15px 0;
    padding: 12px;
    position: relative;
}
.intention-icon{
    top: 14px;
    left: 130px;
}

.intention-header {
    padding: 0 16px;
    height: 48px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E9E9E9;
}

.intention-chart-legend {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
}

.legend-item {
    width: 100%;
    text-align: left;
    margin: 10px 0;
}

.legend-letter {
    display: inline-block;
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
}
.legend-txt{
    padding-left: 6px;
}

.legend-total {
    float: right;
    display: inline-block;
    height: 24px;
    font-size: 13px;
    font-family: Avenir-Medium, Avenir;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 24px;
    padding-left: 6px;
}